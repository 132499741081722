import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaGithub, FaLinkedin } from 'react-icons/fa'; // Importar iconos de react-icons
import ChefTonicLogo from '../images/ChefTonic_nobg.png';
import { useLocation } from 'react-router-dom'; // Importar Link y useLocation de React Router
import 'bootstrap/dist/css/bootstrap.min.css';

const Navigation = () => {
  const location = useLocation(); // Hook para obtener la ubicación actual

  // Función para verificar si la ruta está activa
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <Navbar bg="transparent" expand="lg">
      <Container>
        {/* Marca con icono */}
        <Navbar.Brand href='https://devtonic.es/'>
          <img
            src={ChefTonicLogo} // Usa la imagen importada
            alt="ChefTonic Logo"
            style={{ height: '60px', margin: '10px' }} // Ajusta el tamaño y el margen
          />
        </Navbar.Brand>

        {/* Botón colapsable para móviles */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto w-100 d-lg-flex justify-content-lg-center">
            <Nav.Link href="https://devtonic.es/" className={`me-3 `}>
              Sobre mí
            </Nav.Link>
            <Nav.Link href="https://devtonic.es/projects" className={`me-3 ${isActive('/')}`}>
              Mis Proyectos
            </Nav.Link>
            <Nav.Link href="https://devtonic.es/gustos" className={`me-3 `}>
              Mis Gustos
            </Nav.Link>
            <Nav.Link href="https://devtonic.es/games" className={`me-3 `}>
              Juegos
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="https://github.com/cheftonic92" target="_blank" className="mx-2 nav-icon">
              <FaGithub size={30} />
            </Nav.Link>
            <Nav.Link href="https://www.linkedin.com/in/antonio-barroso-s%C3%A1ez-895811ba/" target="_blank" className="mx-2 nav-icon">
              <FaLinkedin size={30} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
