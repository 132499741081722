import React from 'react';
import { useState } from 'react';
import './App.css';
import ArtistSelection from './components/ArtistSelection';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const [artists, setArtists] = useState([<ArtistSelection key={0} />]);

  // Añadir una nueva caja de selección de artista
  const addArtist = () => {
    setArtists([...artists, <ArtistSelection key={artists.length} />]);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <ToastContainer />
        <h1 className='text-center my-1'>Compara tus artistas favoritos</h1>
        <button className="add-artist-btn mb-3 " onClick={addArtist}>
          Añadir Artista
        </button>
        <div className="artist-container">
          {artists.map((artist) => artist)} {/* Renderizamos todas las cajas */}
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
